import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

import { ConfirmContextProvider } from "./app/components/confirmMessage/store/ConfirmContextProvider";
import ConfirmDialog from "./app/components/confirmMessage/ConfirmDialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContextState from "./app/core/store";
import Client from "./app/core/apollo";


ReactDOM.render(
  <ContextState>
    <ApolloProvider client={Client}>
      <ConfirmContextProvider>
        <BrowserRouter basename="/sendertech-thermoking">
          <App />
        </BrowserRouter>
        <ConfirmDialog />
      </ConfirmContextProvider>
    </ApolloProvider>
    <ToastContainer />
  </ContextState>,
  document.getElementById("root"),
);

serviceWorker.unregister();
