


export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";

export const getAllUsers = (data) => {
    return {
        type: GET_ALL_USERS,
        payload: data
    }
};

export const getUser = (data) => {
  return {
    type: GET_USER,
    ...data,
  };
};
