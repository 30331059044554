import { gql } from "@apollo/client";
import Client from '../../apollo';

const SUPPLIERS_REQUESTS = gql`
  query GetSuppliers {
    suppliers(limit: 1000, offset: 0) {
      data {
        codigo
        status
        dataCadastro 
        razaoSocial
        nomeFantasia
        cpfCnpj
        endereco
        numero
        cep
        bairro
        cidade
        estado
        pais
        telefone
        celular
        email
      }
      totalRecords
    }
  }
`;

export const getAllSuppliers = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: SUPPLIERS_REQUESTS,
            // variables: {
            //   input: bar
            // }
        });
        const { data } = response.data.suppliers;
        resolve(data);
    });
}
