import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import { withTranslation } from "react-i18next";
import ModalPopUp from "./helper/modalEvent";
import { useEventOcurred } from "./hooks/useEventOcurred";

const App = (props) => {
  const [modalEvent, setModalEvent] = useState({ data: null, show: false });
  const [eventOcurred, refreshEventOcurred, confirmEventOcurred] = useEventOcurred();

  const [state, setState] = useState({});
  useEffect(() => {
    onRouteChanged();
    refreshEventOcurred();
    const eventTimer = setInterval(() => {
      refreshEventOcurred();
    }, 30000);

    return () => {
      if (eventTimer) clearInterval(eventTimer);
    };
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [props.location]);

  useEffect(() => {
    if (eventOcurred && !modalEvent.show) {
      setModalEvent({ data: eventOcurred, show: true });
    }
  }, [eventOcurred]);

  const handleCloseModalEvent = (data) => {
    confirmEventOcurred(data);
    setModalEvent({ data: null, show: false });
  };

  const handleConfirmModalEvent = (data) => {
    confirmEventOcurred(data);
    setModalEvent({ data: null, show: false });
  };

  const onRouteChanged = () => {
    const { i18n } = props;
    const body = document.querySelector("body");
    if (props.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
      i18n.changeLanguage("ar");
    } else {
      body.classList.remove("rtl");
      i18n.changeLanguage("en");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login/login-1", "/login/login-2", "/login/register-1", "/login/register-2", "/login/lockscreen", "/error-pages/error-404", "/error-pages/error-500", "/general-pages/landing-page"];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        setState({
          isFullPageLayout: true,
        });
        document.querySelector(".page-body-wrapper").classList.add("full-page-wrapper");
        break;
      } else {
        setState({
          isFullPageLayout: false,
        });
        document.querySelector(".page-body-wrapper").classList.remove("full-page-wrapper");
      }
    }
  };

  let navbarComponent = !state.isFullPageLayout ? <Navbar /> : "";
  let sidebarComponent = !state.isFullPageLayout ? <Sidebar /> : "";
  let footerComponent = !state.isFullPageLayout ? <Footer /> : "";
  return (
    <div className="container-scroller">
      <ModalPopUp data={modalEvent.data} showModal={modalEvent.show} handleClose={handleCloseModalEvent} handleConfirm={handleConfirmModalEvent} />
      {sidebarComponent}
      <div className="container-fluid page-body-wrapper">
        {navbarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(App));
