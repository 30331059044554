import * as ACTION_TYPES from "../../actions/simCard";

export const INITIAL_STATE = {
    simCards: [],
    codigo: 0,
    master: 0,
    status: 0,
    dataCadastro: "00/00/0000",
    iccid: "",
    ddd: "",
    telefone: "",
};

export const SimCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_SIMCARDS:
      return {
        ...state,
        simCards: action.payload,
      };
    case ACTION_TYPES.GET_SIMCARD:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
