import React, { useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

const EVENT_UPDATE_REQUESTS = gql`
  mutation SaveEventOccurred($input: InputEventOccurred!) {
    saveEventOccurred(input: $input)
  }
`;

const EVENT_REQUESTS = gql`
  query GetEventOccurred {
    eventOccurred {
      data {
        codigo
        descricao
        dataHoraGPS
        localizacao
        temperaturaArRetorno
        setPoint
        tipoEvento
        unidadeThermoking {
          codigo
          clienteMaster {
            temperaturas
          }
        }
        carretaMaster {
          codigo
          placa
        }
        viagemMaster {
          pontoOrigem
          pontoDestino
          dataHoraEmbarque
          dataHoraDesembarque
          perfilMaster {
            descricao
            minima
            maxima
            tolerancia
          }
        }
        motoristaMaster {
          nome
        }
      }
    }
  }
`;

export const useEventOcurred = () => {
  const [eventOcurred, setEventOcurred] = useState(null);

  const [fetchEventOccurred, {}] = useLazyQuery(EVENT_REQUESTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      console.log(result);
      const { eventOccurred } = result;
      setEventOcurred(eventOccurred ? eventOccurred.data[0] : null);
    },
    onError: ({ graphQLErrors, networkError }) => {
      console.log('ERROR.....');
      if (graphQLErrors && graphQLErrors[0] === "DELIVERY_TOKEN_NOT_FOUND") {
        console.log("DELIVERY_TOKEN_NOT_FOUND");
      } else if (networkError) {
        console.log(networkError);
      }
    },
  });

  const [setEventOccurred, {}] = useMutation(EVENT_UPDATE_REQUESTS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {},
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors[0] === "DELIVERY_TOKEN_NOT_FOUND") {
        console.log("DELIVERY_TOKEN_NOT_FOUND");
      } else if (networkError) {
        console.log(networkError);
      }
    },
  });

  const refreshEventOcurred = async () => {
    setEventOcurred(null);
    fetchEventOccurred();
  };

  const confirmEventOcurred = async (data) => {
    setEventOcurred(null);
    setEventOccurred({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  return [eventOcurred, refreshEventOcurred, confirmEventOcurred];
};
