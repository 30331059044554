export const GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS";
export const GET_SUPPLIER = "GET_SUPPLIER";

export const getAllSuppliers = (data) => {
    return {
        type: GET_ALL_SUPPLIERS,
        payload: data
    }
};

export const getSupplier = (data) => {
  return {
    type: GET_SUPPLIER,
    ...data,
  };
};
