export const GET_ALL_SHIPPERS = "GET_ALL_SHIPPERS";
export const GET_SHIPPER = "GET_SHIPPER";

export const getAllShippers = (data) => {
    return {
        type: GET_ALL_SHIPPERS,
        payload: data
    }
};

export const getShipper = (data) => {
  return {
    type: GET_SHIPPER,
    ...data,
  };
};
