import * as ACTION_TYPES from "../../actions/supplier";

export const INITIAL_STATE = {
    suppliers: [],
    status: 0,
    dataCadastro: "00/00/0000",
    razaoSocial: "",
    nomeFantasia: "",
    cpfCnpj: "",
    endereco: "", 
    numero: "",
    cep: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "BRL",
    telefone: "",
    celular: "",
    email: "",
};

export const SupplierReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case ACTION_TYPES.GET_SUPPLIER:
      return {
        ...state,
        suppliers: [],
        name: "",
        email: "",
        token: "",
      };
    default:
      return state;
  }
};
