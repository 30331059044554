import * as ACTION_TYPES from "../../actions/customer";

export const INITIAL_STATE = {
    customers: [],
    status: 0,
    dataCadastro: "00/00/0000",
    razaoSocial: "",
    nomeFantasia: "",
    cpfCnpj: "",
    endereco: "", 
    numero: "",
    cep: "",
    bairro: "",
    cidade: "",
    estado: "",
    pais: "BRL",
    telefone: "",
    celular: "",
    email: "",
};

export const CustomerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case ACTION_TYPES.GET_CUSTOMER:
      return {
        ...state,
        customers: [],
        name: "",
        email: "",
        token: "",
      };
    default:
      return state;
  }
};
