import { gql } from "@apollo/client";
import Client from '../../apollo';

const USERS_REQUESTS = gql`
  query GetUsers {
    users(limit: 1000, offset: 0) {
      data {
        id
        dataCadastro
        customer
        name
        email
      }
      totalRecords
    }
  }
`;

const USER_REQUESTS = gql`
  query GetUser($userId: ID!) {
    user(userId: $userId) {
        id
        dataCadastro
        customer
        name
        email
    }
  } 
`;

export const getAllUsers = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: USERS_REQUESTS,
            // variables: {
            //   input: bar
            // }
        });
        const { data } = response.data.users;
        resolve(data);
    });
}

export const getUser = async (userId) => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: USER_REQUESTS,
            variables: {
                userId: parseInt(userId)
            }
        });
        const { user } = response.data;
        return resolve(user);
    });
}