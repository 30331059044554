export const GET_ALL_TRAILERS = "GET_ALL_TRAILERS";
export const GET_TRAILER = "GET_TRAILER";

export const getAllTrailers = (data) => {
    return {
        type: GET_ALL_TRAILERS,
        payload: data
    }
};

export const getTrailer = (data) => {
  return {
    type: GET_TRAILER,
    ...data,
  };
};
