import { gql } from "@apollo/client";
import Client from '../../apollo';

const TRAILERS_REQUESTS = gql`
  query GetTrailers ($limit: Int!, $offset: Int!, $status: [Int!]) {
    trailers(limit: $limit, offset: $offset, status: $status) {
      data {
        codigo
        master
        status
        dataCadastro
        fabricacao
        anoFabricacao
        modelo
        anoModelo
        placa
        chassi
        volume
        capacidade
        tara
        pesoTotal
        unidade
      }
      totalRecords
    }
  }
`;

export const getAllTrailers = async (status) => {
  return new Promise(async (resolve) => {
    const response = await Client.query({
      query: TRAILERS_REQUESTS,
      variables: {
        limit: 1000, 
        offset: 0,
        status
      }
    });
    const { data } = response.data.trailers;
    resolve(data);
  });
}
