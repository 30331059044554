import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { toast } from "react-toastify";

const baseOptions = { 
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  errorPolicy: "all" 
}
const cache = new InMemoryCache({ addTypename: false })

const errorLink = onError(({ operation, response, graphQLErrors = {}, networkError = {} }) => {

  if (networkError) {
    const status = networkError && networkError.statusCode ? networkError.statusCode : null;
    if (status === 401) {
      toast.error('Login ou Senha Inválido(s). Verifique!');
    } else {
      toast.error(`[Network error]: ${networkError}`);
    }
  } else
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        // Location: ${locations}, 
        toast.error(
          `[GraphQL error]: Message: ${message}, Path: ${path}`
        )
      );
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_URI}`,
  opts: {
    credentials: 'include',
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('@STechTk/token');
  return {
    headers: {
      ...headers,
      'x-access-token': token ? `${token}` : "",
    }
  }
});

const Client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
  defaultOptions: baseOptions
});

export default Client;
