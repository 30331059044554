import * as ACTION_TYPES from "../../actions/maintenancePlan";

export const INITIAL_STATE = {
    maintenancePlans: [],
};

export const MaintenancePlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_MAINTENANCE_PLANS:
      return {
        ...state,
        maintenancePlans: action.payload,
      };
    default:
      return state;
  }
};
