import { gql } from "@apollo/client";
import Client from '../../apollo';

const result = `
    data {
      codigo
      master
      status
      dataCadastro
      descricao
      unidade
      intervalo
      antecipacao
      tipoAntecipacao
      tipoManutencao
    }
    totalRecords`;

const MAINTENANCE_PLANS_REQUESTS = gql`
    query GetMaintenancePlans ($limit: Int! $offset: Int!) {
        maintenancePlans(limit: $limit, offset: $offset) {
            ${result}
        }
    }
  `;

export const getAllMaintenancePlans = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: MAINTENANCE_PLANS_REQUESTS,
            variables: {
                limit: 1000,
                offset: 0,
            }
        });
        const { data } = response.data.maintenancePlans;
        resolve(data);
    });
}
