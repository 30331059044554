import React, { Component, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import Context from "../helper/context";

class Sidebar extends Component {
  state = {};
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // this.onRouteChanged();
    }
  }

  handleShowMenuItem(value) {
    this.setState({ ["showMenuItem"]: value });
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/forms", state: "formsMenuOpen" },
      { path: "/maintenance", state: "maintenanceMenuOpen" },
      { path: "/management", state: "managementMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/reports", state: "reportsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/login", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {/* <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={require("../../assets/images/logo.svg")} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div> */}
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle" width="220" height="100" src={require("../../assets/images/faces/sendertech_logo.jpeg")} alt="Sender Tech" />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal">Sender Tech</h5>
                  <span>
                    <Trans>Versão 1.0.1</Trans>
                  </span>
                </div>
              </div>
              <Dropdown alignRight>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Account settings</Trans>
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>Change Password</Trans>
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar-today text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small">
                        <Trans>To-do list</Trans>
                      </p>
                    </div>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>Navegação</Trans>
            </span>
          </li>
          <li className={this.isPathActive("/gesture-map") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/gesture-map">
              <span className="menu-icon">
                <i className="mdi mdi-google-physical-web"></i>
              </span>
              <span className="menu-title">
                <Trans>Mapa Gestão</Trans>
              </span>
            </Link>
          </li>
          <li className={this.isPathActive("/devices-map") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/devices-map">
              <span className="menu-icon">
                <i className="mdi mdi-google-maps"></i>
              </span>
              <span className="menu-title">
                <Trans>Monitoramento</Trans>
              </span>
            </Link>
          </li>
          <li className={this.isPathActive("/gps-map") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/gps-map">
              <span className="menu-icon">
                <i className="mdi mdi-google-circles"></i>
              </span>
              <span className="menu-title">
                <Trans>Mapa GPS</Trans>
              </span>
            </Link>
          </li>
          {/* <li className={this.isPathActive("/dashboard") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li> */}
          {/*
          <li
            className={
              this.isPathActive("/dashboard-calls")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            } 
          >
            <Link className="nav-link" to="/dashboard-calls">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dispositivos</Trans>
              </span>
            </Link>
          </li>
          */}

          <li className={this.isPathActive("/forms") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <div className={this.state.formsMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => this.toggleMenuState("formsMenuOpen")} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-account-multiple-plus"></i>
              </span>
              <span className="menu-title">
                <Trans>Cadastros</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.formsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/customers") ? "nav-link active" : "nav-link"} to="/forms/customers">
                      <Trans>Clientes</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/users") ? "nav-link active" : "nav-link"} to="/forms/users">
                      <Trans>Usuários</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/drivers") ? "nav-link active" : "nav-link"} to="/forms/drivers">
                      <Trans>Motoristas</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/trailers") ? "nav-link active" : "nav-link"} to="/forms/trailers">
                      <Trans>Carretas</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/shippers") ? "nav-link active" : "nav-link"} to="/forms/shippers">
                      <Trans>Embarcador</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/productProfiles") ? "nav-link active" : "nav-link"} to="/forms/productProfiles">
                      <Trans>Perfis de Produto</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/simCards") ? "nav-link active" : "nav-link"} to="/forms/simCards">
                      <Trans>Chips / SimCards</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/units-thermoking") ? "nav-link active" : "nav-link"} to="/forms/units-thermoking">
                      <Trans>Unidades Refrigeradas</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className={this.isPathActive("/forms") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <div className={this.state.managementMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => this.toggleMenuState("managementMenuOpen")} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-domain"></i>
              </span>
              <span className="menu-title">
                <Trans>Gestão</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.managementMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/fuelSupplies") ? "nav-link active" : "nav-link"} to="/forms/fuelSupplies">
                      <Trans>Abastecimentos</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/suppliers") ? "nav-link active" : "nav-link"} to="/forms/suppliers">
                      <Trans>Fornecedores</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/products") ? "nav-link active" : "nav-link"} to="/forms/products">
                      <Trans>Produtos / Serviços</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/travels") ? "nav-link active" : "nav-link"} to="/forms/travels">
                      <Trans>Viagens</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/expenses") ? "nav-link active" : "nav-link"} to="/forms/expenses">
                      <Trans>Despesas</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/commands") ? "nav-link active" : "nav-link"} to="/forms/commands">
                      <Trans>Comandos</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className={this.isPathActive("/forms") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <div className={this.state.maintenanceMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => this.toggleMenuState("maintenanceMenuOpen")} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-wrench"></i>
              </span>
              <span className="menu-title">
                <Trans>Manutenção</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.maintenanceMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/maintenancePlan") ? "nav-link active" : "nav-link"} to="/forms/maintenancePlan">
                      <Trans>Planos de Manutenção</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/forms/maintenanceSchedule") ? "nav-link active" : "nav-link"} to="/forms/maintenanceSchedule">
                      <Trans>Agendas de Manutenção</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className={this.isPathActive("/positions-history") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/positions-history">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Histórico Posições</Trans>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive("/events-history") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <Link className="nav-link" to="/events-history">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title">
                <Trans>Histórico Eventos</Trans>
              </span>
            </Link>
          </li>

          <li className={this.isPathActive("/charts") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <div className={this.state.chartsMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => this.toggleMenuState("chartsMenuOpen")} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-chart-arc"></i>
              </span>
              <span className="menu-title">
                <Trans>Gráficos</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/charts/temperatures") ? "nav-link active" : "nav-link"} to="/charts/temperatures">
                      <Trans>Temperaturas</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          <li className={this.isPathActive("/reports") ? "nav-item menu-items active" : "nav-item menu-items"}>
            <div className={this.state.reportsMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => this.toggleMenuState("reportsMenuOpen")} data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-printer"></i>
              </span>
              <span className="menu-title">
                <Trans>Relatórios</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.reportsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link className={this.isPathActive("/reports/temperatures") ? "nav-link active" : "nav-link"} to="/reports/temperatures">
                      <Trans>Viagens</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

          {/*
          <li
            className={
              this.isPathActive("/health-tips")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/health-tips">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">
                <Trans>Dispositivos</Trans>
              </span>
            </Link>
          </li>
          */}
          {/*<li
            className={
              this.isPathActive("/basic-ui")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.basicUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("basicUiMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-laptop"></i>
              </span>
              <span className="menu-title">
                <Trans>Basic UI Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/basic-ui/buttons")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/basic-ui/buttons"
                    >
                      <Trans>Buttons</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/basic-ui/dropdowns")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/basic-ui/dropdowns"
                    >
                      <Trans>Dropdowns</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/basic-ui/typography")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/basic-ui/typography"
                    >
                      <Trans>Typography</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
                    </li> */}

          {/*
          <li
            className={
              this.isPathActive("/form-elements")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("formElementsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-playlist-play"></i>
              </span>
              <span className="menu-title">
                <Trans>Form Elements</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.formElementsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/form-elements/basic-elements")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/form-elements/basic-elements"
                    >
                      <Trans>Basic Elements</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
                    </li>*/}

          {/*<li
            className={
              this.isPathActive("/charts")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.chartsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("chartsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-chart-bar"></i>
              </span>
              <span className="menu-title">
                <Trans>Charts</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.chartsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/charts/chart-js")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/charts/chart-js"
                    >
                      <Trans>Chart Js</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
                    </li>
          <li
            className={
              this.isPathActive("/icons")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.iconsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("iconsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-contacts"></i>
              </span>
              <span className="menu-title">
                <Trans>Icons</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.iconsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/icons/mdi")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/icons/mdi"
                    >
                      <Trans>Material</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/login")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.userPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("userPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-security"></i>
              </span>
              <span className="menu-title">
                <Trans>User Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/login/login-1")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/login/login-1"
                    >
                      <Trans>Login</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/login/register-1")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/login/register-1"
                    >
                      <Trans>Register</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>*/}
          {/*
          <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>More</Trans>
            </span>
          </li>
         
          <li
            className={
              this.isPathActive("/error-pages")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.errorPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("errorPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-lock"></i>
              </span>
              <span className="menu-title">
                <Trans>Error Pages</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.errorPagesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/error-pages/error-404")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/error-pages/error-404"
                    >
                      404
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/error-pages/error-500")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/error-pages/error-500"
                    >
                      500
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li> 
                    */}
          {/*<li className="nav-item menu-items">
            <a
              className="nav-link"
              href="http://bootstrapdash.com/demo/corona-react-free/documentation/documentation.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="menu-icon">
                <i className="mdi mdi-file-document-box"></i>
              </span>
              <span className="menu-title">
                <Trans>Documentation</Trans>
              </span>
            </a>
          </li>*/}
          {this.state.showMenuItem && (
            <li className="nav-item menu-items" onClick={() => document.body.classList.toggle("sidebar-icon-only")}>
              <div className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-arrow-right"></i>
                </span>
              </div>
            </li>
          )}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    const { pathname } = this.props.location;
    return pathname === path;
  }

  componentDidMount() {
    this.onRouteChanged();

    const handleShowMenuItem = (value) => this.handleShowMenuItem(value);

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".navbar-toggler").forEach((item) => {
      item.addEventListener("click", (event) => {
        handleShowMenuItem(!body.classList.contains("sidebar-icon-only"));
      });
    });

    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
