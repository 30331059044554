export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";

export const getAllCustomers = (data) => {
    return {
        type: GET_ALL_CUSTOMERS,
        payload: data
    }
};

export const getCustomer = (data) => {
  return {
    type: GET_CUSTOMER,
    ...data,
  };
};
