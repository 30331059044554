import * as ACTION_TYPES from "../../actions/user";

export const INITIAL_STATE = {
  users: [],
  status: 0,
  dataCadastro: "00/00/0000",
  nome: '',
};

export const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_USERS:
      return {
        ...state,
        customers: action.payload,
      };
    case ACTION_TYPES.GET_USER:
      return {
        ...state,
        users: [],
        nome: "",
      };
    default:
      return state;
  }
};
