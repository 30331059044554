import * as ACTION_TYPES from "../../actions/driver";

export const INITIAL_STATE = {
    drivers: [],
    codigo: 0,
    master: 0,
    status: 0,
    dataCadastro: "00/00/0000",
    descricao: "",
    categoria: 0,
    valor: 0,
};

export const DriverReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };
    case ACTION_TYPES.GET_DRIVER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
