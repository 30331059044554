import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import useConfirm from "../../components/confirmMessage/hooks/useConfirm";
import { toast } from "react-toastify";

const ModalPopUp = ({ data, showModal, handleClose, handleConfirm }) => {
  const { confirm } = useConfirm();
  const [operatorResolution, setOperatorResolution] = useState("");
  const [customerResolution, setCustomerResolution] = useState("");
  const [action, setAction] = useState(false);

  const handleActInTheEvent = () => { 
    setAction(true);
  };

  const handleActClose = () => {
    handleClose({
      codigo: data ? parseInt(data.codigo) : 0,
      status: 1,
      operatorResolution: "",
      customerResolution: "",
    });
  };

  const handleConfirmActInTheEvent = async () => {
    if (`${operatorResolution}`.trim().length <= 0 && `${customerResolution}`.trim().length <= 0) {
      toast.error("Preencher ao menos uma das Soluções!");
      return;
    }

    const isConfirmed = await confirm("Confirma Baixar Evento?");
    if (!isConfirmed) {
      return;
    }

    setAction(false);
    handleConfirm({
      codigo: parseInt(data.codigo),
      status: 2,
      operatorResolution,
      customerResolution,
    });
  };

  return (
    <Modal show={showModal} onHide={() => { }} centered size="lg" className="modal-center">
      <Modal.Header closeButton>
        <Modal.Title>Notificação de Ocorrência de Evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {action ? (
          <form className="forms-sample" autoComplete="off">
            <div className="row">
              <div className="col-md-12">
                <Form.Group>
                  <label htmlFor="operatorResolution">Resolução do Operador</label>
                  <Form.Control className="form-control" placeholder="resolução do operador" autoComplete="off" as="textarea" rows={3} name="operatorResolution" id="operatorResolution" value={operatorResolution} onChange={(e) => setOperatorResolution(e.target.value)} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group>
                  <label htmlFor="customerResolution">Resolução do Cliente</label>
                  <Form.Control className="form-control" placeholder="resolução do cliente" autoComplete="off" as="textarea" rows={3} name="customerResolution" id="customerResolution" value={customerResolution} onChange={(e) => setCustomerResolution(e.target.value)} />
                </Form.Group>
              </div>
            </div>
          </form>
        ) : (
          <form className="forms-sample" autoComplete="off">
            {data && data.tipoEvento && data.carretaMaster === 0 && data.viagemMaster &&
              <>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      Ocorreu um evento de temperatura fora da parametrização na unidade {data.carretaMaster.placa}, com origem em {data.viagemMaster.pontoOrigem} e destino {data && data.viagemMaster.pontoDestino}, coletada {data && data.viagemMaster.dataHoraEmbarque} com previsão de entrega em {data && data.viagemMaster.dataHoraDesembarque}.
                    </p>
                  </div>
                </div>
                {data && data.viagemMaster && data.viagemMaster.perfilMaster &&
                <div className="row">
                  <div className="col-md-12">
                    <p className="bg-min-max-color">PERFIL CARGA - {data.viagemMaster.perfilMaster.descricao}.</p>
                  </div>
                </div>}
                {data && data.viagemMaster && data.viagemMaster.perfilMaster &&
                <div className="row">
                  <div className="col-md-4">Mínima: {data.viagemMaster.perfilMaster.minima.toFixed(1)} º</div>
                  <div className="col-md-4">Máxima: {data.viagemMaster.perfilMaster.maxima.toFixed(1)} º</div>
                  <div className="col-md-4">Tolerância: {data.viagemMaster.perfilMaster.tolerancia.toFixed(1)} º</div>
                </div>}
                <div className="row">
                  <div className="col-md-12">
                    <p className="bg-alert-color">DADOS OCORRÊNCIA</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">Temperatura: {data && data.temperaturaArRetorno.toFixed(1)} º</div>
                  <div className="col-md-6">Data e Hora: {data && data.dataHoraGPS}</div>
                </div>
                <div className="row">
                  <div className="col-md-12">Localização: {data && data.localizacao}</div>
                </div>
              </>}
            {data && data.tipoEvento === 4 &&
              <>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      Ocorreu um evento de temperatura fora da parametrização do SetPoint, na unidade {data && data.unidadeThermoking.descricao}{data && data.carretaMaster ? ` / ${data.carretaMaster.placa}` : ''}.
                    </p>
                  </div>
                </div>
                {data.unidadeThermoking.clienteMaster && data.unidadeThermoking.clienteMaster.temperaturas && data.unidadeThermoking.clienteMaster.temperaturas.length > 0 &&
                <div className="row">
                  <div className="col-md-12">
                    <p className="bg-min-max-color">PERFIL CARGA - {data && data.unidadeThermoking.clienteMaster ? data.unidadeThermoking.clienteMaster.temperaturas[0].descricao : '----'}.</p>
                  </div>
                </div>}
                {data.unidadeThermoking.clienteMaster && data.unidadeThermoking.clienteMaster.temperaturas && data.unidadeThermoking.clienteMaster.temperaturas.length > 0 &&
                  <div className="row">
                    <div className="col-md-4">Mínimo SetPoint: {data && data.unidadeThermoking.clienteMaster.temperaturas[0].minima.toFixed(1)} º</div>
                    <div className="col-md-4">Máximo SetPoint: {data && data.unidadeThermoking.clienteMaster.temperaturas[0].maxima.toFixed(1)} º</div>
                    <div className="col-md-4">Tolerância: {data && data.unidadeThermoking.clienteMaster.temperaturas[0].tolerancia.toFixed(1)} º</div>
                  </div>}
                <div className="row">
                  <div className="col-md-12">
                    <p className="bg-alert-color">DADOS OCORRÊNCIA</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">Temperatura: {data && data.setPoint.toFixed(1)} º</div>
                  <div className="col-md-6">Data e Hora: {data && data.dataHoraGPS}</div>
                </div>
                <div className="row">
                  <div className="col-md-12">Localização: {data && data.localizacao}</div>
                </div>
              </>}
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {action ? (
          <>
            <Button
              style={{ minWidth: 120 }}
              variant="success"
              onClick={() => {
                handleConfirmActInTheEvent();
              }}
            >
              CONFIRMAR
            </Button>
            <Button
              style={{ minWidth: 120 }}
              variant="primary"
              onClick={() => {
                setAction(false);
              }}
            >
              CANCELAR
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{ minWidth: 120 }}
              variant="success"
              onClick={() => {
                handleActInTheEvent();
              }}
            >
              ATUAR NO EVENTO
            </Button>
            <Button
              style={{ minWidth: 120 }}
              variant="primary"
              onClick={() => {
                handleActClose();
              }}
            >
              OK
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopUp;
