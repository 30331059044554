import { gql } from "@apollo/client";
import Client from '../../apollo';

const PRODUCTS_PROFILE_REQUESTS = gql`
  query GetProductProfiles {
    productProfiles(limit: 100, offset: 0) {
      data {
        codigo 
        master
        status
        dataCadastro
        descricao
        minima
        maxima
        tolerancia
        clienteMaster {
          codigo
          razaoSocial
        }
      }
      totalRecords
    }
  }
`;

export const getAllProductsProfile = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: PRODUCTS_PROFILE_REQUESTS,
            // variables: {
            //   input: bar
            // }
        });
        const { data } = response.data.productProfiles;
        resolve(data);
    });
}
