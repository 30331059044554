import * as ACTION_TYPES from "../../actions/product";

export const INITIAL_STATE = {
    products: [],
    codigo: 0,
    master: 0,
    status: 0,
    dataCadastro: "00/00/0000",
    descricao: "",
    categoria: 0,
    valor: 0,
};

export const ProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case ACTION_TYPES.GET_PRODUCT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
