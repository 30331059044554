export const GET_ALL_SIMCARDS = "GET_ALL_SIMCARDS";
export const GET_SIMCARD = "GET_SIMCARD";

export const getAllSimCards = (data) => {
    return {
        type: GET_ALL_SIMCARDS,
        payload: data
    }
};

export const getSimCard = (data) => {
  return {
    type: GET_SIMCARD,
    ...data,
  };
};
