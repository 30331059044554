export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_DATA = "SET_USER_DATA";

export const login = (data) => {
  return {
    type: LOGIN,
    ...data,
  };
};

export const setUserData = (data) => {
  return {
    type: USER_DATA,
    ...data,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
