import * as ACTION_TYPES from "../../actions/shipper";

export const INITIAL_STATE = {
    shippers: [],
    codigo: 0,
    master: 0,
    status: 0,
    dataCadastro: "00/00/0000",
    descricao: "",
    categoria: 0,
    valor: 0,
};

export const ShipperReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_SHIPPERS:
      return {
        ...state,
        shippers: action.payload,
      };
    case ACTION_TYPES.GET_SHIPPER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
