import React, { useReducer } from "react";
import Context from "../helper/context";
import jwt from 'jwt-decode';

import * as authActions from "./actions/authActions";
import * as authReducer from "./reducers/authReducer";

import * as customerActions from "./actions/customer";
import * as customerReducer from "./reducers/customer";

import * as unitThermokingActions from "./actions/unitThermoking";
import * as unitThermokingReducer from "./reducers/unitThermoking";

import * as maintenancePlanActions from "./actions/maintenancePlan";
import * as maintenancePlanReducer from "./reducers/maintenancePlan";

import * as supplierActions from "./actions/supplier";
import * as supplierReducer from "./reducers/supplier";

import * as productActions from "./actions/product";
import * as productReducer from "./reducers/product";

import * as productProfileActions from "./actions/productProfile";
import * as productProfileReducer from "./reducers/productProfile";

import * as trailerActions from "./actions/trailer";
import * as trailerReducer from "./reducers/trailer";

import * as shipperActions from "./actions/shipper";
import * as shipperReducer from "./reducers/shipper";

import * as driverActions from "./actions/driver";
import * as driverReducer from "./reducers/driver";

import * as simCardActions from "./actions/simCard";
import * as simCardReducer from "./reducers/simCard";

import * as userActions from "./actions/user";
import * as userReducer from "./reducers/user";

import { getAllCustomers } from "./services/customer";
import { getAllSuppliers } from "./services/supplier";
import { getAllProducts } from "./services/product";
import { getAllProductsProfile } from "./services/productProfile";
import { getAllTrailers } from "./services/trailer";
import { getAllShippers } from "./services/shipper";
import { getAllDrivers } from "./services/driver";
import { getAllSimCards } from "./services/simCard";
import { getAllUnitsThermoking } from "./services/unitThermoking";
import { getAllMaintenancePlans } from "./services/maintenancePlan";
import { getAllUsers, getUser } from "./services/user";

const ContextState = ({ children }) => {
  const loadUserData = () => {
    const data = localStorage.getItem("@STechTk/userData");
    if (data) {
      return JSON.parse(data).name;
    }
    return "Suporte - Sendertech";
  }

  const [stateAuthReducer, dispatchAuthReducer] = useReducer(
    authReducer.AuthReducer,
    { 
      ...authReducer.initialState,
      isAuth: localStorage.getItem("@STechTk/token") !== null,
      token: localStorage.getItem("@STechTk/token") || "",
      name: loadUserData(),
    }
  );

  const [stateUnitThermoking, dispatchUnitThermoking] = useReducer(
    unitThermokingReducer.UnitThermokingReducer,
    {
      ...unitThermokingReducer.INITIAL_STATE,
    }
  )

  const [stateMaintenancePlan, dispatchMaintenancePlan] = useReducer(
    maintenancePlanReducer.MaintenancePlanReducer,
    {
      ...maintenancePlanReducer.INITIAL_STATE,
    }
  )

  const [stateCostumer, dispatchCustomer] = useReducer(
    customerReducer.CustomerReducer,
    {
      ...customerReducer.INITIAL_STATE,
    }
  )

  const [stateSupplier, dispatchSupplier] = useReducer(
    supplierReducer.SupplierReducer,
    {
      ...supplierReducer.INITIAL_STATE,
    }
  )

  const [stateProduct, dispatchProduct] = useReducer(
    productReducer.ProductReducer,
    {
      ...productReducer.INITIAL_STATE,
    }
  )

  const [stateProductProfile, dispatchProductProfile] = useReducer(
    productProfileReducer.ProductProfileReducer,
    {
      ...productProfileReducer.INITIAL_STATE,
    }
  )

  const [stateTrailer, dispatchTrailer] = useReducer(
    trailerReducer.TrailerReducer,
    {
      ...trailerReducer.INITIAL_STATE,
    }
  )

  const [stateShipper, dispatchShipper] = useReducer(
    shipperReducer.ShipperReducer,
    {
      ...shipperReducer.INITIAL_STATE,
    }
  )

  const [stateDriver, dispatchDriver] = useReducer(
    driverReducer.DriverReducer,
    {
      ...driverReducer.INITIAL_STATE,
    }
  )

  const [stateSimCard, dispatchSimCard] = useReducer(
    simCardReducer.SimCardReducer,
    {
      ...simCardReducer.INITIAL_STATE,
    }
  )

  const [stateUser, dispatchUser] = useReducer(
    userReducer.UserReducer,
    {
      ...userReducer.INITIAL_STATE,
    }
  )

  const handleLogin = (data) => {
    const { accessToken } = data;
    const decode = jwt(accessToken);
    dispatchAuthReducer(authActions.login(data));
    handleUser(decode.id);
  };

  const handleLogout = () => {
    dispatchAuthReducer(authActions.logout());
  };

  const handleCustomers = async () => {
    let data = await getAllCustomers();
    dispatchCustomer(customerActions.getAllCustomers(data));
  }

  const handleUnitsThermoking = async (status) => {
    let data = await getAllUnitsThermoking(status);
    dispatchUnitThermoking(unitThermokingActions.getAllUnitsThermoking(data));
  }

  const handleMaintenancePlans = async () => {
    let data = await getAllMaintenancePlans();
    dispatchMaintenancePlan(maintenancePlanActions.getAllMaintenancePlans(data));
  }

  const handleSuppliers = async () => {
    let data = await getAllSuppliers();
    dispatchSupplier(supplierActions.getAllSuppliers(data));
  }

  const handleShippers = async () => {
    let data = await getAllShippers();
    dispatchShipper(shipperActions.getAllShippers(data));
  }

  const handleProducts = async () => {
    let data = await getAllProducts();
    dispatchProduct(productActions.getAllProducts(data));
  }

  const handleProductsProfile = async () => {
    let data = await getAllProductsProfile();
    dispatchProductProfile(productProfileActions.getAllProductsProfile(data));
  }

  const handleTrailers = async (status) => {
    let data = await getAllTrailers(status);
    dispatchTrailer(trailerActions.getAllTrailers(data));
  }

  const handleDrivers = async () => {
    let data = await getAllDrivers();
    dispatchDriver(driverActions.getAllDrivers(data));
  }

  const handleSimCards = async (unityId) => {
    let data = await getAllSimCards(unityId);
    dispatchSimCard(simCardActions.getAllSimCards(data));
  }

  const handleUsers = async () => {
    let data = await getAllUsers();
    dispatchUser(userActions.getAllUsers(data));
  }

  const handleUser = async (id) => {
    let data = await getUser(id); 
    localStorage.setItem("@STechTk/userData", JSON.stringify(data));
    dispatchAuthReducer(authActions.setUserData(data));
  }

  return (
    <Context.Provider
      value={{
        authState: stateAuthReducer.isAuth,
        userName: stateAuthReducer.name,
        tokenState: stateAuthReducer.token,
        handleUserLogin: (username) => handleLogin(username),
        handleUserLogout: () => handleLogout(),

        customers: stateCostumer.customers,
        handleGetCustomers: () => handleCustomers(),

        unitsThermoking: stateUnitThermoking.unitsThermoking,
        handleGetUnitsThermoking: (status = [0, 1]) => handleUnitsThermoking(status),

        maintenancePlans: stateMaintenancePlan.maintenancePlans,
        handleGetMaintenancePlans: () => handleMaintenancePlans(),

        suppliers: stateSupplier.suppliers,
        handleGetSuppliers: () => handleSuppliers(),

        shippers: stateShipper.shippers,
        handleGetShippers: () => handleShippers(),

        products: stateProduct.products,
        handleGetProducts: () => handleProducts(),

        productsProfile: stateProductProfile.productsProfile,
        handleGetProductsProfile: () => handleProductsProfile(),

        trailers: stateTrailer.trailers,
        handleGetTrailers: (status = [0, 1]) => handleTrailers(status),

        drivers: stateDriver.drivers,
        handleGetDrivers: () => handleDrivers(),

        simCards: stateSimCard.simCards,
        handleGetSimCards: (unityId) => handleSimCards(unityId),

        users: stateUser.users,
        handleGetUsers: () => handleUsers(),

        user: stateUser.user,
        handleGetUser: () => handleUser(),
      }}
    > 
      {children}
    </Context.Provider>
  );
};

export default ContextState;
