export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const GET_DRIVER = "GET_DRIVER";

export const getAllDrivers = (data) => {
    return {
        type: GET_ALL_DRIVERS,
        payload: data
    }
};

export const getDriver = (data) => {
  return {
    type: GET_DRIVER,
    ...data,
  };
};
