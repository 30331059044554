import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "./shared/Spinner";
import PrivateRoute from "./helper/PrivateRoute";
import PublicRoute from "./helper/PublicRoute";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const DashboardCalls = lazy(() => import("./dashboard/DashboardCalls"));

const MapThermoking = lazy(() => import("./map"), {
  ssr: false,
});

const MapGesture = lazy(() => import("./mapGesture"), {
  ssr: false,
});

const MapGps = lazy(() => import("./mapGps"), {
  ssr: false,
});

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));
const PositionHistory = lazy(() => import("./historical/positions"));
const EventsOccurredHistory = lazy(() => import("./historical/eventsOccurred"));
const UsersPlatform = lazy(() => import("./usersPlatform/UsersPlatform"));

const Customers = lazy(() => import("./forms/customer"));
const Commands = lazy(() => import("./forms/command"));
const Suppliers = lazy(() => import("./forms/supplier"));
const Products = lazy(() => import("./forms/product"));
const Drivers = lazy(() => import("./forms/driver"));
const SimCards = lazy(() => import("./forms/simCard"));
const Trailers = lazy(() => import("./forms/trailer"));
const Shippers = lazy(() => import("./forms/shipper"));
const ProductProfiles = lazy(() => import("./forms/productProfile"));
const Expenses = lazy(() => import("./forms/expense"));
const FuelSupplies = lazy(() => import("./forms/fuelSupply"));
const MaintenancePlan = lazy(() => import("./forms/maintenancePlan"));
const MaintenanceSchedule = lazy(() => import("./forms/maintenanceSchedule"));
const Travels = lazy(() => import("./forms/travel"));

const Users = lazy(() => import("./forms/user"));
const UnitsThermoking = lazy(() => import("./forms/unitsThermoking"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));
const ChartTemperatures = lazy(() => import("./charts/temperatures"));
const ReportTemperatures = lazy(() => import("./reports/temperatures"));
const ReportTravels = lazy(() => import("./reports/travel"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./login/Login"));
const Register1 = lazy(() => import("./login/Register"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/dashboard-calls" component={DashboardCalls} />
          <PrivateRoute path="/positions-history" component={PositionHistory} />
          <PrivateRoute path="/events-history" component={EventsOccurredHistory} />
          <PrivateRoute path="/devices-map" component={MapThermoking} />
          <PrivateRoute path="/gesture-map" component={MapGesture} />
          <PrivateRoute path="/gps-map" component={MapGps} />
          <PrivateRoute path="/users-platform" component={UsersPlatform} />
          <PrivateRoute path="/basic-ui/buttons" component={Buttons} />
          <PrivateRoute path="/basic-ui/dropdowns" component={Dropdowns} />
          <PrivateRoute path="/basic-ui/typography" component={Typography} />

          <PrivateRoute path="/forms/customers" component={Customers} />
          <PrivateRoute path="/forms/suppliers" component={Suppliers} />
          <PrivateRoute path="/forms/products" component={Products} />
          <PrivateRoute path="/forms/drivers" component={Drivers} />
          <PrivateRoute path="/forms/trailers" component={Trailers} />
          <PrivateRoute path="/forms/shippers" component={Shippers} />
          <PrivateRoute path="/forms/productProfiles" component={ProductProfiles} />
          <PrivateRoute path="/forms/commands" component={Commands} />
          <PrivateRoute path="/forms/travels" component={Travels} />
          <PrivateRoute path="/forms/simCards" component={SimCards} />

          <PrivateRoute path="/forms/expenses" component={Expenses} />
          <PrivateRoute path="/forms/fuelSupplies" component={FuelSupplies} />
          <PrivateRoute path="/forms/maintenancePlan" component={MaintenancePlan} />
          <PrivateRoute path="/forms/maintenanceSchedule" component={MaintenanceSchedule} />

          <PrivateRoute path="/forms/users" component={Users} />
          <PrivateRoute path="/forms/units-thermoking" component={UnitsThermoking} />
          <PrivateRoute path="/form-Elements/basic-elements" component={BasicElements} />
          <PrivateRoute path="/tables/basic-table" component={BasicTable} />
          <PrivateRoute path="/icons/mdi" component={Mdi} />
          <PrivateRoute path="/charts/chart-js" component={ChartJs} />
          <PrivateRoute path="/charts/temperatures" component={ChartTemperatures} />
          <PrivateRoute path="/reports/temperatures" component={ReportTemperatures} />
          <PrivateRoute path="/reports/travels" component={ReportTravels} />

          <PublicRoute path="/login/login-1" component={Login} />
          <PrivateRoute path="/login/register-1" component={Register1} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          <Redirect to="/gesture-map" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
