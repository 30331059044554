import { gql } from "@apollo/client";
import Client from '../../apollo';

const result = `
    data {
        codigo
        status
        cliente
        dataCadastro
        dataAquisicao
        dataFabricacao
        serialRastreador
        placa
        descricao
        serialThermoking
        serialUnidadeThermoking
        modeloControladora
        modeloUnidade
        horimetroVirtual
        carretaMaster {
          placa
        }
    }
    totalRecords`;

const UNITS_THERMOKING_REQUESTS = gql`
    query GetUnitsThermoking ($limit: Int! $offset: Int!, $status: [Int!]) {
        unitsThermoking(limit: $limit, offset: $offset, status: $status) {
            ${result}
        }
    }
  `;

export const getAllUnitsThermoking = async (status) => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: UNITS_THERMOKING_REQUESTS,
            variables: {
                limit: 1000, 
                offset: 0,
                status,
            }
        });
        const { data } = response.data.unitsThermoking || [];
        resolve(data);
    });
}
