import { gql } from "@apollo/client";
import Client from '../../apollo';

const PRODUCTS_REQUESTS = gql`
  query GetProducts {
    products(limit: 100, offset: 0) {
      data {
        codigo
        descricao
        categoria
        valor
      }
      totalRecords
    }
  }
`;

export const getAllProducts = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: PRODUCTS_REQUESTS,
            // variables: {
            //   input: bar
            // }
        });
        const { data } = response.data.products;
        resolve(data);
    });
}
