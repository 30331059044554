export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";

export const getAllProducts = (data) => {
    return {
        type: GET_ALL_PRODUCTS,
        payload: data
    }
};

export const getProduct = (data) => {
  return {
    type: GET_PRODUCT,
    ...data,
  };
};
