import * as ACTION_TYPES from "../../actions/trailer";

export const INITIAL_STATE = {
    trailers: [],
    codigo: 0,
    master: 0,
    status: 0,
    dataCadastro: "00/00/0000",
    descricao: "",
    categoria: 0, 
    valor: 0,
};

export const TrailerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_TRAILERS:
      return {
        ...state,
        trailers: action.payload,
      };
    case ACTION_TYPES.GET_TRAILER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
