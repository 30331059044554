export const GET_ALL_PRODUCTS_PROFILE = "GET_ALL_PRODUCTS_PROFILE";
export const GET_PRODUCT_PROFILE = "GET_PRODUCT_PROFILE";

export const getAllProductsProfile = (data) => {
    return {
        type: GET_ALL_PRODUCTS_PROFILE,
        payload: data
    }
};

export const getProduct = (data) => {
  return {
    type: GET_PRODUCT_PROFILE,
    ...data,
  };
};
