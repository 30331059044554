import { gql } from "@apollo/client";
import Client from '../../apollo';

const SIMCARDS_REQUESTS = gql`
  query GetSimCards ($unityId: Int!) {
    simCardsAvailable(unityId: $unityId, limit: 100, offset: 0) {
      data {
        codigo
        master
        status
        dataCadastro
        iccid
        ddd
        telefone
        clienteMaster {
          codigo
          razaoSocial
        }
      }
      totalRecords
    }
  }
`;

export const getAllSimCards = async (unityId) => {
  return new Promise(async (resolve) => {
    const response = await Client.query({
      query: SIMCARDS_REQUESTS,
      variables: {
        unityId: parseInt(unityId),
      }
    });
    const { data } = response.data.simCardsAvailable;
    resolve(data);
  });
}
