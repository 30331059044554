import { gql } from "@apollo/client";
import Client from '../../apollo';

const DRIVERS_REQUESTS = gql`
  query GetDrivers {
    drivers(limit: 100, offset: 0) {
      data {
        codigo
        master
        status
        dataCadastro
        nome
        cnh
        categoria
        validade
        clienteMaster {
          codigo
          razaoSocial
        }
      }
      totalRecords
    }
  }
`;

export const getAllDrivers = async () => {
    return new Promise(async (resolve) => {
        const response = await Client.query({
            query: DRIVERS_REQUESTS,
            // variables: {
            //   input: bar
            // }
        });
        const { data } = response.data.drivers;
        resolve(data);
    });
}
