import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import Context from "./context";

const PrivateRoute = (props) => {
  const context = useContext(Context);
  const { component: Component, ...rest } = props;
  const render = (props) => {
    if (!context.authState) {
      return <Redirect to={"/login/login-1"} />;
    }

    return <Component {...props} />;  
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
